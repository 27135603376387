import * as React from 'react';
import { Checkbox, CheckboxProps, MuiThemeProvider } from '@material-ui/core';
import { ICellRendererParams } from '@ag-grid-community/core';
import { muiTheme } from 'src/utils/Style/Theme';
import { style } from 'typestyle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

type Props = ICellRendererParams & {
  value?: boolean;
  onChange?: (checked: boolean, rowData?: any) => void;
  isEditable: boolean;
  allowIndeterminate?: boolean;
};

type State = {};

const checkStyle = style({
  $debugName: 's5-checkbox',
  width: '100%',
  textAlign: 'center',
  $nest: {
    '&.Mui-disabled': {
      cursor: 'not-allowed',
    },
  },
});

export default class CheckboxCellRenderer extends React.Component<Props, State> {
  checkMap = {
    on: {
      checked: true,
      indeterminate: false,
    },
    off: {
      checked: false,
      indeterminate: false,
    },
    indeterminate: {
      checked: false,
      indeterminate: true,
    },
  };
  constructor(props: Props) {
    super(props);

    // for `allowIndeterminate` we assume/require the values are sent in
    // in valid boolean format.
  }

  componentWillUnmount() {
    // Any destruction calls need to be done here, not in destroy()
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.props.onChange) {
      this.props.onChange(event.target.checked);
    }

    if (this.props.isEditable) {
      if (this.props.setValue) {
        this.props.setValue(!this.props.value);
      }
    }
  };

  getValue() {
    return this.props.value;
  }

  isPopup() {
    return false;
  }

  // this is how we determine indeterminance for standard checkboxes
  // if value is not true/false it's "indeterminant" (any other than
  // checked/notchecked essentially)
  getCheckSetting = (checked: unknown) => {
    if (checked === true || checked === 1 || checked === 'true') {
      return this.checkMap['on'];
    } else if (checked === false || checked === '') {
      return this.checkMap['off'];
    } else {
      return this.checkMap['indeterminate'];
    }
  };

  render() {
    const { isEditable, allowIndeterminate, value } = this.props;
    let statusProps: Partial<CheckboxProps>;
    if (allowIndeterminate) {
      statusProps = this.getCheckSetting(this.props.value);
    } else {
      statusProps = {
        checked: value === true || value === 1 || value === 'true' ? true : false,
      };
    }
    const disabledStyle = !isEditable ? { cursor: 'not-allowed' } : { cursor: 'pointer' };
    return (
      <MuiThemeProvider theme={muiTheme}>
        {/* theme provider re-exported here because for reasons that are unclear, the theme doesn't apply witin this component correctly */}
        <div style={disabledStyle}>
          <Checkbox
            icon={<RadioButtonUncheckedIcon />}
            checkedIcon={<CheckCircleOutlineIcon />}
            className={checkStyle}
            onChange={this.handleChange}
            disabled={!isEditable}
            disableRipple={!isEditable}
            {...statusProps}
          />
        </div>
      </MuiThemeProvider>
    );
  }
}
